import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import { IconButton, useColorMode } from '@chakra-ui/react';
import React from 'react';
import tw, { styled } from 'twin.macro';

interface Props {}

const Header = styled.h1`
  ${tw`border border-green-400 rounded-xl m-6 bg-yellow-400`}
`;

export const NavigationBar: React.FC = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <nav className='flex items-center justify-between flex-wrap py-20 w-full z-10'>
      <div className='flex items-center flex-shrink-0  '>
        <AliFarooqLogo />
      </div>
      <div className='flex items-center space-x-10'>
        <IconButton
          bg='transparent'
          size='md'
          icon={
            colorMode === 'light' ? (
              <MoonIcon w='5' h='5' />
            ) : (
              <SunIcon w='5' h='5' />
            )
          }
          onClick={toggleColorMode}
        />
        {/* <Link className='text-lg' href='/'>
          Home
        </Link>
        <Link className='text-lg' href='/about'>
          About
        </Link> */}
      </div>
    </nav>
  );
};

const AliFarooqLogo = () => {
  return (
    <div>
      <svg
        width='36'
        height='36'
        viewBox='0 0 46 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M0 12.5217C0 5.60617 5.55368 0 12.4045 0H46V35.4783C46 42.3938 40.4463 48 33.5955 48V30.2609H17.573C14.7185 30.2609 12.4045 32.5968 12.4045 35.4783H28.427C28.427 42.3938 22.8733 48 16.0225 48H0V30.2609C0 23.3453 5.55368 17.7391 12.4045 17.7391H28.427C31.2815 17.7391 33.5955 15.4032 33.5955 12.5217H0Z'
          fill='currentColor'
        />
      </svg>
    </div>
  );
};
